import "babel-polyfill";
import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import createPalette from '@material-ui/core/styles/createPalette';
import './App.css';
import Order from './components/Order'
import Faq from './components/Faq'
import Index from './components/Index'
import PrinterMapWidget  from './components/PrinterMapWidget.js';
import Printers from './components/Printers'
import PrinterRedirect from './components/PrinterRedirect'
import OrderNew from "./components/OrderNew";
import Loading from './components/Loading';
import pink from '@material-ui/core/colors/pink'
import amber from '@material-ui/core/colors/amber'

import {I18nextProvider} from 'react-i18next';
import { Suspense } from 'react';
import i18n from './i18n';

const zeccerTheme = createTheme({
  palette: createPalette({
    primary: pink,
    accent: amber
  }),
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={zeccerTheme}>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<Loading/>}>
          <BrowserRouter>
            <div>
              <MetaTags>
                <title>Zeccer Express</title>
              </MetaTags>
              <Routes>
                <Route path="/index" element={<Index/>} exact={true} />
                <Route path="/faq" element={<Faq/>} exact={true} />
                <Route path="/printer_map_widget" element={<PrinterMapWidget/>} exact={true} />
                <Route path="/zabka_map_widget" element={<PrinterMapWidget filterName="żabka" clusterImage="images/cluster/marker_zabka_cluster.svg"/>} exact={true} />
                <Route path="/printers/:printerEmail" element={<PrinterRedirect />} exact />
                <Route path="/printers/:printerId/orders/new" element={<OrderNew/>} exact={true} />
                <Route path="/:orderId" element={<Order/>} exact={true} />
                <Route path="/" element={<Printers/>} exact={true} />
              </Routes>
            </div>
          </BrowserRouter>
          </Suspense>
        </I18nextProvider>
      </ThemeProvider>
    );
  }
}

export default App;
