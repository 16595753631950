import AppContent from "./AppContent";
import Footer from './Footer';
import React, {Component} from 'react';
import TitleBar from './TitleBar';
import PrinterList from './PrinterList';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      language: 'en',
      printers: [],
    };

    this.getPrinters = this.getPrinters.bind(this);
  }

  render() {
    return(
      <AppContent className="content">
        <TitleBar />
        <PrinterList
          printers={this.state.printers}
          loading={this.state.loading}
        />
        <Footer />
      </AppContent>
    )
  }

  getPrinters() {
    return fetch(this.apiUrl('/printers')).then(function(response) {
      return response.json();
    }).then((json) => {
      this.setState({printers: json, loading: false});
    }).catch(function(ex) {
      console.log('parsing failed', ex);
    });
  }

  apiUrl(path) {
    return process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_PREFIX + path;
  }

  componentDidMount() {
    this.getPrinters();
  }
}

export default Index;
