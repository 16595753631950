import React, {Component} from 'react';
import ReactGA from 'react-ga4';
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import logo from './blik-logo.png'
import { withTranslation } from 'react-i18next';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Grid from "@material-ui/core/Grid";
import ConfirmPaymentDialog from './ConfirmPaymentDialog';

const styles = {
  image: {
    height: '50px',
    marginRight: '20px'
  },
  caption: {
    marginTop: '5px'
  },
  input: {
    width: '100px'
  }
}

class BlikForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      code: '',
      started: false,
      processing: false,
      openDialog: false
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }
  render() {
    const { classes, t, paymentStatus } = this.props;

    return (
      <>
      <form
        onSubmit={this.handleSubmit}>
        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
          <Hidden xsDown>
            <Grid item sm={4}>
              <img src={logo} alt="blik-logo" className={classes.image} />
            </Grid>
          </Hidden>
          <Grid item xs={6} sm={4} container justifyContent="flex-end">
            <div>
              <FormControl error={this.state.code !== '' && ((this.state.started && paymentStatus === 'failed') || !this.state.valid)} disabled={this.inputDisabled()}>
                <InputLabel>{t('blikCode')}</InputLabel>
                <Input className={classes.input} onChange={this.handleChange} value={this.state.code} type="tel" />
              </FormControl>
              <Typography type="caption" className={classes.caption}>
                { this.statusMsg(t) }
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!this.state.valid || this.inputDisabled()}>
                {(this.state.started && paymentStatus === 'processing') || this.state.processing ?
                  <CircularProgress size={14} />
                  : t('buyAndPay')
                }
            </Button>
          </Grid>
        </Grid>
      </form>
      <ConfirmPaymentDialog
        open={this.state.openDialog}
        onClose={this.handleClose}
        handleCloseDialog={this.handleCloseDialog}/>
      </>
    )
  }
  handleCloseDialog() {
    this.setState({ openDialog: false });
  }
  statusMsg(t) {
    if ((this.state.started && this.props.paymentStatus === 'processing') || this.state.processing) {
      return t('blikPaymentConfirm');
    } else if (this.state.started && this.props.paymentStatus === 'failed') {
      return t('blikPaymentFailed');
    } else {
      return t('blikPaymentGenerateCode');
    }
  }
  inputDisabled() {
    return this.props.disabled || this.state.processing || (this.state.started && this.props.paymentStatus === 'processing');
  }

  handleChange = e => {
    const value = e.target.value.replace(/\D/g, '').substr(0, 6);
    const valid = value.length === 6;
    this.setState({'valid': valid, 'code': value})
  }

  handleSubmit = e => {
    e.preventDefault();

    if (!this.state.valid) {
      return;
    }

    // Check if emailInput is valid
    if (!this.props.validateEmail()) {
      this.props.scrollToEmail();
      return;
    }

    ReactGA.event({
      category: 'Payment',
      action: 'started',
      label: 'blik'
    });

    const that = this;
    that.setState({processing: true});
    that.setState({ openDialog: true });

    // Update email before payment
    this.props.updateEmail(() => {
      fetch(that.props.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          provider: 'blik',
          blik_code: this.state.code,
        })
      }).then(function(response) {
        return response.json();
      }).then(function(json) {
        ReactGA.event({
          category: 'Payment',
          action: 'completed',
          label: 'blik'
        });
        that.props.onPaymentCompleted(json);
        that.setState({processing: false, started: true});
        if (json.payment.status === "completed") {
          that.setState({ openDialog: false });
        }
      });
    });
  }
}

export default withStyles(styles)(withTranslation()(BlikForm));
