import AppContent from "./AppContent";
import TitleBar from "./TitleBar";
import Footer from "./Footer";
import Card from "@material-ui/core/Card";
import React from "react";
import {withStyles} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import CardContent from "@material-ui/core/CardContent";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = {

  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    textAlign: "center",
    paddingBottom: 0,
    paddingTop: 24
  },
  category: {
    paddingTop: 16,
    paddingBottom: 16
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    color: "red"
  },
  pos: {
    marginBottom: 12,
  },
  expansionPanel: {
    width: "100%"
  },
  loaderCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
    marginTop: 20,
    width: '100%'
  },
};

class Faq extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      items: null
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_HOST + "/v1/pages/faq")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result
        });
      }
    )
  }

  render() {
    const { classes, i18n } = this.props;
    const { isLoaded, items } = this.state;
    const language = i18n.language || i18n.options.fallbackLng[0]
    return (
      <AppContent>
        <TitleBar/>
        {!isLoaded &&
          <Card className={classes.loaderCard}>
            <CircularProgress/>
          </Card>
        }
        {isLoaded &&
          <Card className={classes.card}>
            <CardHeader className={classes.header} title='FAQ'></CardHeader>
            <CardContent className={classes.cardContent}>
              {items && items.categories.map(category =>
                <React.Fragment key={"react" + category.id.toString()}>
                  <Typography className={classes.category} variant="h5">{category.name[language]}</Typography>
                  {items.questions.filter(question => question.categoryId === category.id).map(question =>
                    <ExpansionPanel key={"question" + question.id.toString()} className={classes.expansionPanel}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography> {question.content[language]}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography className={classes.heading}>{question.answer[language]}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                </React.Fragment>
              )}
            </CardContent>
          </Card>
        }
        <Footer/>
      </AppContent>
    );
  }
}

export default withStyles(styles)(withTranslation()(Faq));
