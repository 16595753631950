import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const styles = {
  root: {
    textAlign: 'right',
    marginTop: '20px'
  },
  paragraph: {
    lineHeight: '1.25',
    fontSize: '12px',
    textAlign: 'center',
    marginTop: '12px'
  },
};

function PrintButton(props) {
  const { className, classes, t } = props;
  return (
    <div className={classNames(classes.root, className)}>
      <Button
        variant="contained"
        color="primary"
        onClick={props.onClick}
        disabled={props.disabled}
        className={classNames(classes.button, className)}
      >
        {t('print')}
      </Button>
      <Typography variant="body2" className={classNames(classes.paragraph, className)}>
        {t('termsAndConditions1')}<a href="https://zeccer.pl/pdf/regulamin.pdf">{t('termsOfService')}</a> {t('and')} <a href="https://zeccer.pl/pdf/polityka-prywatnosci.pdf">{t('privacyPolicy')}</a> {t('termsAndConditions2')}
      </Typography>
    </div>
  );
}

export default withStyles(styles)(withTranslation()(PrintButton));
