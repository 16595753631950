import React, {Component} from 'react';
import ReactGA from 'react-ga4';
import BraintreeDropin from 'braintree-dropin-react'
import braintree from 'braintree-web-drop-in'
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import InfoBar from './InfoBar';
import BlikForm from './BlikForm';
import Przelewy24Form from './Przelewy24Form';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import blik_logo from './blik-logo.png'
import p24_logo from './przelewy24.svg'
import sofort_logo from './sofort.svg'
import CardContent from "@material-ui/core/CardContent/CardContent";

const styles = {
    root: {
        marginTop: '20px',
    }
};

class PaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {state: 'loading', btToken: null, language: 'en'};
        props.i18n.on('languageChanged', this.updateLanguage);
    }

    updateLanguage = () => {
        this.setState({language: this.state.language})
    }

    render() {
        const {className, classes, t} = this.props;

        if (this.props.paymentCompleted) {
            return (
                <>
                    <InfoBar
                        caption={t('paymentCompleted')}
                        type="success"/>
                </>
            )
        }

        if (this.state.state === 'processing') {
            return (
                <InfoBar
                    caption={t('paymentProcessing')}
                    type="info"/>
            )
        }

        return (
            <div>
                {this.props.paymentMethods.includes('blik') &&
                    <Card className={classNames(classes.root, className)}>
                        <CardContent>
                            <BlikForm
                                paymentStatus={this.props.paymentStatus}
                                onPaymentCompleted={this.props.onPaymentCompleted}
                                submitUrl={this.props.blikSubmitUrl}
                                disabled={this.props.disabled || this.state === 'started'}
                                validateEmail={this.props.validateEmail}
                                scrollToEmail={this.props.scrollToEmail}
                                updateEmail={this.props.updateEmail}
                            />
                        </CardContent>
                    </Card>
                }
                {this.props.paymentMethods.includes('p24_blik') &&
                    <Card className={classNames(classes.root, className)}>
                        <CardContent>
                            <Przelewy24Form
                                blik={true}
                                logo={blik_logo}
                                submitUrl={this.props.przelewy24SubmitUrl}
                                disabled={this.props.disabled || this.state === 'started'}
                                validateEmail={this.props.validateEmail}
                                scrollToEmail={this.props.scrollToEmail}
                                updateEmail={this.props.updateEmail}
                            />
                        </CardContent>
                    </Card>
                }
                {this.props.paymentMethods.includes('p24') &&
                    <Card className={classNames(classes.root, className)}>
                        <CardContent>
                            <Przelewy24Form
                                blik={false}
                                logo={p24_logo}
                                submitUrl={this.props.przelewy24SubmitUrl}
                                disabled={this.props.disabled || this.state === 'started'}
                                validateEmail={this.props.validateEmail}
                                scrollToEmail={this.props.scrollToEmail}
                                updateEmail={this.props.updateEmail}
                            />
                        </CardContent>
                    </Card>
                }
                {this.props.paymentMethods.includes('sofort') &&
                    <Card className={classNames(classes.root, className)}>
                        <CardContent>
                            <Przelewy24Form
                                blik={false}
                                logo={sofort_logo}
                                submitUrl={this.props.przelewy24SubmitUrl}
                                disabled={this.props.disabled || this.state === 'started'}
                                validateEmail={this.props.validateEmail}
                                scrollToEmail={this.props.scrollToEmail}
                                updateEmail={this.props.updateEmail}
                            />
                        </CardContent>
                    </Card>
                }
                {this.state.btToken !== null && this.props.paymentMethods.includes('braintree') &&
                    <Card className={classNames(classes.root, className)}>
                        <CardContent>
                            <BraintreeDropin
                                locale={this.state.language}
                                renderSubmitButton={this.renderSubmitButton(t)}
                                handlePaymentMethod={(method) => {
                                    ReactGA.event({
                                        category: 'Payment',
                                        action: 'started',
                                        label: 'braintree'
                                    });

                                    this.setState({state: 'processing'});

                                    const that = this;

                                    // Update email before payment
                                    this.props.updateEmail(() => {
                                        that.props.handlePaymentMethod(method)
                                    });
                                }}
                                braintree={braintree}
                                authorizationToken={this.state.btToken}
                                onCreate={() => {
                                    this.setState({state: 'created'});
                                }}
                                onError={(error) => {
                                    this.setState({state: 'error'});
                                    console.log(error);
                                }}/>
                        </CardContent>
                    </Card>
                }
            </div>
        );
    }

    renderSubmitButton = (t) => {
        return ({onClick, isDisabled}) => {
            if (this.state.state !== 'created') {
                return null;
            }
            return (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                        // Check if email is valid
                        if (!this.props.validateEmail()) {
                            this.props.scrollToEmail();
                            return;
                        }

                        this.setState({state: 'started'});
                        onClick(e);
                    }}
                    disabled={isDisabled || this.props.disabled}>{t('buyAndPay')}</Button>
            );
        };
    }


    getBtToken = () => {
        const that = this;
        fetch(this.props.btTokenUrl).then(function (response) {
            return response.json();
        }).then(function (json) {
            that.setState(json);
        });
    }

    componentDidMount() {
        this.getBtToken();
    }
}

export default withStyles(styles)(withTranslation()(PaymentForm));
