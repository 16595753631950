import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ErrorIcon from '@material-ui/icons/Error';
import PrintIcon from '@material-ui/icons/Print';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import CardContent from "@material-ui/core/CardContent/CardContent";
import { Link } from 'react-router-dom';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: '20px',
    height: '60px',
    width: '60px',
  }
};

class PrinterBasicInfo extends Component {
  render() {
    const { classes, t, printer } = this.props;

    const address = printer ? printer.address : t('printerAddress');
    const name = printer ? printer.name : t('printerName');
    const link = printer ? `/printers/${printer.id}` : '#'

    return(
        <Card>
          <CardContent className={classes.root}>
            {printer && printer.locationLogoId &&
            <img src={process.env.REACT_APP_STORAGE_URL + '/' + printer.locationLogoId} style={{height: 60, marginRight: 20}} alt={name}/>
            }

            {printer && !printer.locationLogoId &&
              <Avatar className={classes.avatar}>
                {printer && printer.enabled ?
                  <PrintIcon/>
                  : <ErrorIcon/>}
              </Avatar>
            }
            <div>
              <Typography
                component="h3"
                type="headline"
                data-cy="basicInfo.printerName"
              >
                <Link to={link} color="inherit">
                  {name}
                </Link>
              </Typography>
              <Typography color="secondary">
                  {address}
              </Typography>
            </div>
          </CardContent>
        </Card>
    );
  }
}

export default withStyles(styles)(withTranslation()(PrinterBasicInfo));
