import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Icon from '@material-ui/core/Icon'
import {Link} from "react-router-dom";
import Tidio from './Tidio'

var styles = () => {
  return {
    root: {
      textAlign: 'center',
      paddingTop: 16,
      paddingBottom: 16
    },
    icon: {
      fontSize: 14
    }
  }
};


function Footer(props) {
  const { t, classes } = props;

  return(
    <>
      <div className={classes.root}>
        <Typography>
          Made with <Icon className={classes.icon} color="primary">favorite</Icon> by <a href="https://zeccer.pl">Zeccer</a>. Contact us: <a href="mailto:wsparcie@zeccer.pl">wsparcie@zeccer.pl</a> / <a href="tel:+48530657000">+48 530657000</a> / <Link to="/Faq">FAQ</Link> / <Link to="/Index">index</Link>
        </Typography>
        <Typography>
          <a href="https://zeccer.pl/pdf/regulamin.pdf">{t('termsOfService1')}</a> / <a href="https://zeccer.pl/pdf/polityka-prywatnosci.pdf">{t('privacyPolicy1')}</a>
        </Typography>
      </div>
      <Tidio />
    </>
  );
}

export default withStyles(styles)(withTranslation()(Footer));
