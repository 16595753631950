import React from 'react';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { green, lightBlue, red, grey } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import CardContent from '@material-ui/core/CardContent/CardContent';

const styles = {
  root: {
    marginTop: 20,
  },
  error: {
    background: red[500]
  },
  success: {
    background: green[500]
  },
  info: {
    background: lightBlue[500],
  },
  span: {
    verticalAlign: 'middle',
  },
  icon: {
    marginRight: 16,
    verticalAlign: 'middle',
    width: 40,
    textAlign: 'center'
  },
  content: {
    paddingTop: 24
  },
  typography: {
    display: 'flex',
    alignItems: 'center',
    color: grey[50],
  }
};

function InfoBar(props) {
  const ICONS = {
    error: 'error',
    success: 'done',
    info: 'info'
  };

  const {caption, type, classes, className} = props;
  const icon = ICONS[type];

  return (
    <Card className={classNames(classes.root, classes[type], className || null)}>
      <CardContent className={classes.content}>
        <Typography type="subheading" className={classes.typography}>
          <Icon className={classes.icon}>{icon}</Icon>
          <span className={classes.span}>
            {caption}
            {props.number && <span>{props.number}</span>}
          </span>
        </Typography>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(InfoBar);
