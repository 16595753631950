export default function oncePerTransaction(transactionID, idempotencyKey, func) {
  var key = `oncePerTransaction.${transactionID}.${idempotencyKey}`

  if (localStorage.getItem(key)) {
    return;
  }

  func();
  localStorage.setItem(key, true)
}
