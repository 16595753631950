import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {withTranslation} from 'react-i18next';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import red from '@material-ui/core/colors/red';

const styles = {
  labelAsterisk: {
    color: red[500],
  },
  card: {
    marginTop: 20,
  },
};

class EmailForm extends React.Component {
  handleBlur = () => {
    this.props.updateEmail();
  };

  render() {
    const { t, classes } = this.props;

    return(
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={
                  <span>
                    <span className={classes.labelAsterisk}>* </span>
                    {t('enterYourEmail')}
                  </span>
                }
                value={this.props.emailInput || ''}
                error={this.props.errorMessage !== null}
                onChange={this.props.onEmailChange}
                onBlur={this.handleBlur}
                margin="none"
                fullWidth={true}
                helperText={this.props.errorMessage !== null ? t(this.props.errorMessage) : null}
                inputProps={{
                  'data-cy': 'emailInput',
                }}
              />
            </Grid>
            <Grid item container xs={12} sm={6} alignItems="center">
              <Typography variant="body2">
                {t('emailFormInfo')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(withTranslation()(EmailForm));
