import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";

const styles = {
  buttonWrapper: {
    flex: '0 0 auto',
    margin: '8px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
};

class DiscountCodeDialog extends React.Component {
  state = {
    open: false,
    valid: true,
    code: ''
  };

  handleClickOpen = () => {
    // Check if email is valid
    if (!this.props.validateEmail()) {
      this.props.scrollToEmail();
      return;
    }

    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, code: '', valid: true });
  };

  handleApply = () => {
    const that = this;

    // Update email before submit discount code
    this.props.updateEmail(() => {
      fetch(that.props.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          value: this.state.code
        })
      }).then(function(response) {
        return response.json();
      }).then(function(json) {
        if (json.error) {
          that.setState({ valid: false })
        } else {
          that.props.onCodeApplied(json);
        }
      });
    });
  };

  handleChange = e => {
    this.setState({ code: e.target.value, valid: true });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div>
        <div className={classes.buttonWrapper}>
          <Button onClick={this.handleClickOpen}>{t('discountCode')}</Button>
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll="body"
        >
          <DialogTitle>{t('discountCode')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('discountCodeContent')}
            </DialogContentText>
            <TextField
              error={!this.state.valid}
              autoFocus
              margin="dense"
              id="name"
              label={t('code')}
              fullWidth
              onChange={this.handleChange}
              helperText={this.state.valid ? '' : t('discountCodeInvalid')}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} >
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={this.handleApply} color="primary">
              {t('apply')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(withTranslation()(DiscountCodeDialog));
