import React, {useEffect} from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';

var styles = theme => {
  return {
    content: {
      flex: '1 1 100%',
      maxWidth: '100%',
      margin: '0 auto',
    },
    [theme.breakpoints.up(948)]: {
      content: {
        maxWidth: 900,
      },
    },
  }
};

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER_ID
};

const fbPixelOptions = {
  autoConfig: false,
  debug: false,
};

function AppContent(props) {
  useEffect(() => {
    if (!props.skipTracking) {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, {}, fbPixelOptions);
      window.fbq.disablePushState = true;
      ReactPixel.pageView();
      TagManager.initialize(tagManagerArgs);
    }
  });

  const { className, classes, children: childrenProp } = props;

  return(
      <div className={classNames(classes.content, className)}>
        {childrenProp}
      </div>
  );
}

export default withStyles(styles)(AppContent);
