import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  addButton: {
    marginTop: 3,
    marginBottom: 11,
    marginRight: 4,
    height: 48,
    width: 48,
  },
});

class PrintjobAdd extends Component {
  constructor(props) {
    super(props);

    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleFileSelect(event) {
    const files = Array.from(event.target.files);
    this.props.uploadFiles(files);
    this.fileInput.value = '';
  }

  handleClick() {
    this.fileInput.click();
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <input
          type="file"
          ref={(ref) => { this.fileInput = ref; }}
          onChange={this.handleFileSelect}
          style={{ display: 'none' }}
          multiple
          data-cy="printjobs.add"
        />
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.addButton}
          onClick={this.handleClick}
          disabled={this.props.disabled}
        >
          <AddIcon />
        </Fab>
      </Fragment>
    );
  }
}

PrintjobAdd.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  uploadFiles: PropTypes.func.isRequired
};

export default withStyles(styles)(withTranslation()(PrintjobAdd));
