import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { withTranslation } from 'react-i18next';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import { withStyles } from '@material-ui/core/styles';

const styles = {
  dialogbox: {
    position: 'fixed',
    top: '20%'
  }
};

class ConfirmPaymentDialog extends React.Component {
  render() {
    const { classes, t } = this.props;
    return (
      <Dialog
        className={classes.dialogbox}
        open={this.props.open}
        onClose={this.props.handleCloseDialog}
        scroll="body"
      >
        <DialogTitle>{t('confirmPayment')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('blikPaymentConfirmCode')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.handleCloseDialog} color="primary">
            {t('ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default withStyles(styles)(withTranslation()(ConfirmPaymentDialog));
