import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

i18n
  .use(LanguageDetector)
  .use(XHR)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['pl', 'en', 'ua', 'ru', 'es'],
    backend: {
      queryStringParams: { r: Math.random() }
    }
  });

export default i18n;
