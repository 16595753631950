import React, {Component} from 'react';

import Avatar from '@material-ui/core/Avatar';
import ErrorIcon from '@material-ui/icons/Error';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

const styles = {
  avatar: {
    marginRight: '12px'
  },
  text: {
    overflow: 'hidden',
    maxWidth: '100%',
    '& h3': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '140px'
    },
  },
  container: {
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    top: '-3px',
    left: '-3px'
  }
};

class PrintjobUploaded extends Component {
  render() {
    const {t, classes, uploadedFile} = this.props;

    return(
      <ListItem>
        <div className={classes.container}>
          <Avatar className={classes.avatar}>
            {uploadedFile.uploadingError !== null ? <ErrorIcon /> : <CloudUploadIcon />}
          </Avatar>
          <CircularProgress
            className={classes.progress}
            variant="indeterminate"
            size={46}
            value={uploadedFile.uploadingProgress}
          />
        </div>
        <ListItemText
          className={classes.text}
          primary={uploadedFile.file.name}
          secondary={
            uploadedFile.uploadingError ? t('documentAddUploadError') : t('documentAddUploadInfo')
          }/>
      </ListItem>
    )
  }
}

export default withStyles(styles)(withTranslation()(PrintjobUploaded));
