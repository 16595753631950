import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import LanguageIcon from '@material-ui/icons/Language';
import { withTranslation } from 'react-i18next';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

const languages = ['en', 'pl', 'ua', 'ru', 'es'];

class SimpleMenu extends React.Component {
  constructor(props) {
    super(props);

    props.i18n.on('languageChanged', (lng) => { this.setState({language: lng}) });
    this.state = {
      anchorEl: null,
      open: false
    };
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, code) => {
    this.setState({ open: false})
    this.props.i18n.changeLanguage(code);
  };

  render() {
    return (
      <div>
        <Button
          aria-owns={this.state.open ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          data-cy="language.button"
        >
          <LanguageIcon/>
          {this.props.i18n.language}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onExit={this.handleRequestClose}
          data-cy="language.menu"
        >
        {languages.map((code) => (
          <MenuItem
            key={code}
            onClick={event => this.handleMenuItemClick(event, code)}
          >
            {code.toUpperCase()}
          </MenuItem>
         ))
        }
        </Menu>
      </div>
    );
  }
}

export default withTranslation()(SimpleMenu);
