import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2),
    }
});

const AdServerZoneContainer = ({children, classes}) => {

  return(
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default withStyles(styles)(AdServerZoneContainer);
