import React, {Component} from 'react';
import ReactGA from 'react-ga4';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const styles = {
  image: {
    height: '50px',
    marginRight: '20px'
  }
};

class Przelewy24Form extends Component {
  render() {
    const { t, classes, logo } = this.props;
    return (
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item xs={6} sm={4}>
          <img src={logo} alt="provider-logo" className={classes.image} />
        </Grid>
        <Hidden xsDown>
          <Grid item sm={4}/>
        </Hidden>
        <Grid item xs={6} sm={4} container justifyContent="flex-end">
          <form
            onSubmit={this.handleSubmit}>
            <Button
              variant="contained"
              disabled={this.props.disabled}
              type="submit"
              color="primary">
              {t('buyAndPay')}
            </Button>
          </form>
        </Grid>
      </Grid>);
  }

  handleSubmit = e => {
    e.preventDefault();

    // Check if emailInput is valid
    if (!this.props.validateEmail()) {
      this.props.scrollToEmail();
      return;
    }

    ReactGA.event({
      category: 'Payment',
      action: 'started',
      label: 'p24'
    });

    const that = this;

    // Update email before payment
    this.props.updateEmail(() => {
      fetch(that.props.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          provider: that.props.blik ? 'przelewy24_blik' : 'przelewy24',
        })
      }).then(function(response) {
        return response.json();
      }).then(function(json) {
        if (json.payment.redirectUrl) {
          window.location = json.payment.redirectUrl;
        }
      });
    });
  }
}

export default withStyles(styles)(withTranslation()(Przelewy24Form));
