import AppContent from "./AppContent";
import TitleBar from "./TitleBar";
import Footer from "./Footer";
import Card from "@material-ui/core/Card";
import React from "react";
import {withStyles} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import {Navigate} from "react-router";
import InfoBar from "./InfoBar";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";

function withParams(Component) {
  // eslint-disable-next-line react/display-name
  return props => <Component {...props} params={useParams()} />;
}

const styles = {
  card: {
    marginTop: 24,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  message: {
    marginTop: 24,
  }
};

class OrderNew extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      orderUuid: null,
    };

    this.createOrder = this.createOrder.bind(this);
  }

  componentDidMount() {
    this.createOrder();
  }

  createOrder() {
    const formData = new FormData();
    formData.append('printer_id', this.props.params.printerId);

    const url = process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_PREFIX + '/orders';

    fetch(url, {
      method: 'POST',
      body: formData,
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }).then(
      result => {
        this.setState({orderUuid: result.uuid});
      }
    ).catch(() => {
      this.setState({error: true});
    });
  }

  render() {
    const { t, classes } = this.props;

    // Redirect to order if it was created
    if (this.state.orderUuid !== null) {
      return <Navigate to={`/${this.state.orderUuid}`} replace={true} />;
    }

    return (
      <AppContent>
        <TitleBar />
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            {!this.state.error &&
              <React.Fragment>
                <CircularProgress size={64}/>
                <Typography className={classes.message} variant="body1">
                  {t('creatingOrder')}
                </Typography>
              </React.Fragment>
            }
            {this.state.error &&
              <InfoBar
                caption={t('creatingOrderError')}
                type="error" />
            }
          </CardContent>
        </Card>
        <Footer />
      </AppContent>
    );
  }
}

export default withParams(withStyles(styles)(withTranslation()(OrderNew)));
