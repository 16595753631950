import { useEffect } from 'react';

const Tidio = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//code.tidio.co/yrao7i8ictsvezynye1e617vedjjqqrw.js";
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null; // This component doesn't need to render anything
};

export default Tidio;
