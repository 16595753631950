import React from 'react';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const styles = {
  loaderCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
    marginTop: 20,
  }
};

const PrinterList = ({classes, printers, loading}) => {
  var cities = {};
  for (const printer of printers) {
    if (!cities[printer.addressDetails.city]) {
      cities[printer.addressDetails.city] = [];
    }
    cities[printer.addressDetails.city].push(printer)
  }
  if (loading) {
    return (
      <Card className={classes.loaderCard}>
        <CircularProgress/>
      </Card>
    );
  }

  return(
    Object.keys(cities).map((key) => {
      return (
        <div key={key}>
          <h1>{key}</h1>
          <ul>
          {
            cities[key].map((printer) => {
              return (
                <li key={printer.id}>
                  <Link to={`/printers/${printer.id}`}>{printer.name}</Link>
                </li>
              )
            })
          }
          </ul>
        </div>
      )
    })
  )
};

export default withStyles(styles)(PrinterList);
