import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  root: {
    position: 'absolute',
    left: 10,
    bottom: 20,
    zIndex: 1,
  },
  paper: {
    borderRadius: 2,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  }
};

class FilterButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    const { classes, t, showOnlyColorPrinters } = this.props;

    return (
      <div className={classes.root}>
          <Paper className={classes.paper} elevation={1}>
            <FormControlLabel control={<Checkbox onClick={(e) => showOnlyColorPrinters(e.target.checked)} />} label={t('colorFilter')} />
          </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(FilterButton));
