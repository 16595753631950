import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import DiscountCodeDialog from './DiscountCodeDialog';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import CardContent from '@material-ui/core/CardContent/CardContent';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import classNames from 'classnames';

const styles = {
  right: {
    textAlign: 'right',
    paddingRight: 0
  },
  first: {
    width: '100%'
  },
  noBorder: {
    border: 'none',
  },
};

class Summary extends Component {
  render() {
    const { t, classes, summary, disabled, discountCodeSubmitUrl, onDiscountCodeApplied } = this.props;

    const bottomRowClass = disabled ? classes.noBorder : null;

    return (
        <Card>
          <CardContent>
            <Typography variant="body2">{t('summary')}</Typography>
            <Table>
              <TableBody>
                { summary.pageCount.color !== 0 &&
                  <TableRow>
                    <TableCell className={classes.first}>
                      {t('colorPages')}
                    </TableCell>
                    <TableCell className={classes.right}>
                      {summary.pageCount.color}&nbsp;×
                    </TableCell>
                    <TableCell>
                      {this.formatPrice(summary.prices.color)}
                    </TableCell>
                  </TableRow>
                }
                { summary.pageCount.monochrome !== 0 &&
                  <TableRow>
                    <TableCell className={classes.first}>
                      {t('monochromePages')}
                    </TableCell>
                    <TableCell className={classes.right}>
                      {summary.pageCount.monochrome}&nbsp;×
                    </TableCell>
                    <TableCell>
                      {this.formatPrice(summary.prices.monochrome)}
                    </TableCell>
                  </TableRow>
                }
                {this.showServiceFee() &&
                  <TableRow>
                    <TableCell>
                      {t('serviceFee')}
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                      {this.formatPrice(summary.prices.service)}
                    </TableCell>
                  </TableRow>
                }
                { summary.discountCode &&
                  <TableRow>
                    <TableCell>
                      {t('discountCode')} ({summary.discountCode.value})
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                      -{summary.discountCode.percent}%
                    </TableCell>
                  </TableRow>
                }
                <TableRow>
                  <TableCell className={bottomRowClass}>
                    <b>{t('totalCost')}</b>
                  </TableCell>
                  <TableCell className={bottomRowClass}>
                  </TableCell>
                  <TableCell className={classNames(bottomRowClass)}>
                    <b>{this.formatPrice(this.total())}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            { !disabled &&
              <DiscountCodeDialog
                onCodeApplied={onDiscountCodeApplied}
                submitUrl={discountCodeSubmitUrl}
                validateEmail={this.props.validateEmail}
                scrollToEmail={this.props.scrollToEmail}
                updateEmail={this.props.updateEmail}
              />
            }
          </CardContent>
        </Card>
   );
  }

  total() {
    const summary = this.props.summary;

    let total = summary.prices.monochrome * summary.pageCount.monochrome + summary.prices.color * summary.pageCount.color;

    if (this.showServiceFee()) {
      total += summary.prices.service;
    }

    if (summary.discountCode) {
      total *= (100.0 - summary.discountCode.percent)/100;
    }

    return total
  }

  showServiceFee() {
    const summary = this.props.summary;

    return summary.pageCount.color > 0 || summary.pageCount.monochrome > 0;
  }

  formatPrice(price) {
    return (price/100.0).toFixed(2).replace('.', ',') + '\u00A0' + this.isoToSymbol(this.props.summary.currency);
  }

  isoToSymbol(currency_code) {
    const map = {'PLN': 'zł', 'EUR': '€'}
    return map[currency_code] || currency_code;
  }
}

export default withStyles(styles)(withTranslation()(Summary));
