import React from 'react';
import AppContent from "./AppContent";
import CircularProgress from '@material-ui/core/CircularProgress';

function Loading() {
  return(
    <AppContent className="content" skipTracking="true">
      <CircularProgress/>
    </AppContent>
  )
}
export default(Loading);
