import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../logo.svg';
import LanguageMenu from './LanguageMenu'
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const styles = {
  logo: {
    paddingRight: 24,
    height: 40
  },
  flex: {
    flex: 1
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

function TitleBar(props) {
  return(
    <AppBar position="static">
      <Toolbar>
        <Link to='/'>
          <img src={logo} alt="logo" className={props.classes.logo}/>
        </Link>
        <div className={props.classes.flex}/>
        <LanguageMenu/>
      </Toolbar>
    </AppBar>
  );
}

export default withStyles(styles)(withTranslation()(TitleBar));
