import React from "react";
import popupImg from "./UImages/popup-img.png";
import cross from "./UImages/cross.png";
import Button from '@material-ui/core/Button/Button';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class PopupPaymentCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };

    this.props = props;
  }

  handleButtonUrl = () => {
    if(this.props.printer.maps.url === "") {
      this.setState({ open: false });
    } else {
      window.location.href = this.props.printer.maps.url;
    }
  };

  render() {
    const {t} = this.props;
    const printerUrl = '/printers/' + this.props.printer.id + '/orders/new';
    return (
      <Dialog
        open={this.state.open}
      >
        <DialogContent>
          <Box xs={12} textAlign="right">
            <Button style={{marginRight: '-15px'}} onClick={() => this.setState({ open: false })}>
            <img src={cross} alt="cross"/>
            </Button>
          </Box>
          <Box textAlign="center" m={2}>
            <img src={popupImg} alt="printer"/>
          </Box>
          <DialogContentText align="center">{t('popupCompletedPrint')}</DialogContentText>
          <Box textAlign="center" mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button
                  fullWidth={true}
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={this.handleButtonUrl}
                >
                  {t('popupCompletedPrintOkButton')}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button fullWidth={true} size="large" href={printerUrl} variant="outlined" color={"primary"}>{t('popupCompletedPrintAnotherOrderButton')}</Button>
              </Grid>
            </Grid>
          </Box>
          <Box textAlign="center" m={2}>
            <Button variant="text" color="text.secondary" href={'https://zeccer.pl/wsparcie/'}>{t('popupCompletedPrintCommentsButton')}</Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withTranslation()(PopupPaymentCompleted);
