import AdServerZoneContainer from './AdServerZoneContainer';
import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import AdServerZone from './AdServerZone';
import PropTypes from 'prop-types';


const PrinterAdServerZone = ({printerId}) => (
  <AdServerZoneContainer>
    <Hidden smDown>
      <AdServerZone
        zoneId={process.env.REACT_APP_ADSERVER_ZONE_ID_PRINTER_FOOTER_DESKTOP}
        width={750}
        height={100}
        entity={printerId}
      />
    </Hidden>
    <Hidden mdUp>
      <AdServerZone
        zoneId={process.env.REACT_APP_ADSERVER_ZONE_ID_PRINTER_FOOTER_MOBILE}
        width={300}
        height={250}
        entity={printerId}
      />
    </Hidden>
  </AdServerZoneContainer>
);

PrinterAdServerZone.propTypes = {
  printerId: PropTypes.string.isRequired,
};

export default PrinterAdServerZone;