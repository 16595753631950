import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';
import { withTranslation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ClearIcon from '@material-ui/icons/Clear';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

const styles = theme => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    zIndex: 1,
  },
  inputContainer: {
    flex: 1,
    position: 'relative',
    paddingLeft: 10,
  },
  paper: {
    marginTop: 10,
    borderRadius: 2,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },
  searchIcon: {
    color: grey.A200,
  },
  input: {
    paddingLeft: 5,
    width: '100%',
  },
  localizeButton: {
    zIndex: 1,
    backgroundColor: '#ffffff',
    color: '#666666',
    borderRadius: 2,
    padding: 0,
    margin: 10,
    width: 40,
    height: 40,
    minWidth: 'auto',
  },
  suggestion: {
    height: theme.spacing(7),
    paddingTop: 0,
    paddingBottom: 0,
  },
  suggestionText: {
    '& span': {
      lineHeight: 1.1,
    },
    '& p': {
      lineHeight: 1.1,
    },
  },
  suggestionAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: grey['900'],
  },
  flipHorizontal: {
    transform: 'scaleX(-1)'
  },
});

class PrinterMapSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: '',
    };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.handleChange(address);

    geocodeByAddress(address)
      .then(results => {
        const result = results[0];

        const bounds = result.geometry.bounds;

        if (bounds) {
          // If place have bounds fit map to them
          this.props.fitBounds(bounds);
        } else {
          // Otherwise center map on place location
          this.props.setMapLocation(result.geometry.location, 16);
        }
      })
      .catch(error => console.error('Error', error));
  };

  clearAddress = () => {
    this.setState({address: ''});
  };

  render() {
    const { classes, t } = this.props;

    return (
      <Grid
        container
        className={classes.root}
        wrap="nowrap"
      >
        <div className={classes.inputContainer}>
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                <Paper className={classes.paper} elevation={2}>
                  {this.state.address === '' &&
                    <SearchIcon className={classes.searchIcon}/>
                  }

                  <InputBase
                    {...getInputProps({
                      placeholder: t('searchPrinterByAddress'),
                      className: classes.input,
                      inputProps: {
                        'data-cy': 'search.input',
                      },
                    })}
                  />
                  {this.state.address !== '' &&
                    <IconButton
                      onClick={this.clearAddress}
                      data-cy="search.clear"
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                </Paper>
                <Paper elevation={3}>
                  <List
                    component="nav"
                    disablePadding={true}
                    data-cy="search.suggestions"
                  >
                    {suggestions.map(suggestion =>
                      <ListItem
                        key={suggestion.placeId}
                        className={classes.suggestion}
                        button
                        divider={true}
                        selected={suggestion.active}
                        {...getSuggestionItemProps(suggestion)}
                        data-cy="search.suggestions.item"
                      >
                        <ListItemAvatar>
                          <Avatar className={classes.suggestionAvatar}>
                            <LocationOnIcon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={classes.suggestionText}
                          primary={suggestion.formattedSuggestion.mainText}
                          secondary={suggestion.formattedSuggestion.secondaryText}
                        />
                        <CallMadeIcon className={classes.flipHorizontal} fontSize="small" />
                      </ListItem>
                    )}
                  </List>
                </Paper>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <Button
          variant="contained"
          className={classes.localizeButton}
          onClick={this.props.locateDevice}
        >
          <GpsFixedIcon />
        </Button>
      </Grid>
    );
  }
}

PrinterMapSearch.propTypes = {
  locateDevice: PropTypes.func.isRequired,
  setMapLocation: PropTypes.func.isRequired,
  fitBounds: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(PrinterMapSearch));
