import React, {Component} from 'react';
import PrinterMap from './PrinterMap';
import TitleBar from './TitleBar';
import Footer from './Footer';
import AppContent from "./AppContent";

class Printers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      language: 'en',
      printers: [],
    };

    this.getPrinter = this.getPrinter.bind(this);
  }

  render() {
    return (
      <AppContent className="content">
        <TitleBar />
        <PrinterMap
          printers={this.state.printers}
          apiKey={process.env.REACT_APP_GMAPS_EMBED_API_KEY}
          loading={this.state.loading}
        />
        <Footer />
      </AppContent>
    )
  }

  getPrinter() {
    return fetch(this.apiUrl('/printers')).then(function(response) {
      return response.json();
    }).then((json) => {
      this.setState({printers: json, loading: false});
    }).catch(function(ex) {
      console.log('parsing failed', ex);
    });
  }

  apiUrl(path) {
    return process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_PREFIX + path;
  }

  componentDidMount() {
    this.getPrinter();
  }
}

export default Printers;
