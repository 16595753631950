import React from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    border: 0,
  }
}

class AdServerZone extends React.Component {
  // Allow ad reloading only when entity change
  shouldComponentUpdate(nextProps) {
    return this.props.entity !== nextProps.entity;
  }

  render() {
    const {classes, zoneId, width, height, entity} = this.props;

    return (
      <Iframe
        className={classes.root}
        url={process.env.REACT_APP_ADSERVER_HOST + '/www/delivery/afr.php?zoneid=' + zoneId + (entity !== undefined ? '&entity=' + entity : '') + '&cb=' + Math.random()}
        width={width + 'px'}
        height={height + 'px'}
        position="relative"
      />
    );
  }
}

AdServerZone.propTypes = {
  zoneId: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  entity: PropTypes.string,
};

export default withStyles(styles)(AdServerZone);
