import * as React from "react";
import Card from "@material-ui/core/Card";
import {withStyles} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Fab from '@material-ui/core/Fab';

const styles = {
  card: {
    position: 'relative',
    zIndex: 0,
  },
  cardMedia: {
    paddingBottom: (1169 / 826) * 100 + '%',
  },
  progress: {
    position: 'absolute',
    zIndex: -1,
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
  pageButton: {
    height: 40,
    width: 40,
    marginLeft: 16,
    marginRight: 16,
  },
  currentPage: {
    userSelect: 'none',
  },
  grayscale: {
    WebkitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)',
  },
};

class PrintjobPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };

    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  previousPage() {
    if (this.state.page > 1) {
      this.setState({page: this.state.page - 1});
    }
  }

  nextPage() {
    if (this.state.page < this.props.pageCount) {
      this.setState({page: this.state.page + 1});
    }
  }

  render() {
    const {classes} = this.props;

    return(
      <React.Fragment>
        <Card className={classes.card}>
          <CardMedia
            className={[classes.cardMedia, this.props.isGrayscale ? classes.grayscale : null].join(' ')}
            image={this.props.orderUrl('/printjobs/' + this.props.id + '/preview/' + this.state.page)}
            data-cy="settingsDialog.preview"
          />
          <CircularProgress className={classes.progress} />
        </Card>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 16,
        }}>
          <Fab
            className={classes.pageButton}
            color="secondary"
            aria-label="Previous page"
            disabled={!(this.state.page > 1)}
            onClick={this.previousPage}
            data-cy="settingsDialog.previousPageButton"
          >
            <KeyboardArrowLeft />
          </Fab>
          <Typography className={classes.currentPage}>
            {this.state.page} / {this.props.pageCount}
          </Typography>
          <Fab
            className={classes.pageButton}
            color="secondary"
            aria-label="Previous page"
            disabled={!(this.state.page < this.props.pageCount)}
            onClick={this.nextPage}
            data-cy="settingsDialog.nextPageButton"
          >
            <KeyboardArrowRight />
          </Fab>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PrintjobPreview);
